export interface GameState {
  gameStatus: Status;
}

export enum Status {
  Winning,
  Loosing,
  Playing,
  NotPlaying,
}

export const initialGameState: GameState = {
  gameStatus: Status.NotPlaying,
};

import { useContext } from "react";
import { GameContext } from "../../store/context";
import { Status } from "../../store/state";
import Particles from "react-tsparticles";

import {
  IOptions,
  RecursivePartial,
  ShapeType,
  OutMode,
  MoveDirection,
  StartValueType,
  InteractivityDetect,
  // ClickMode,
  HoverMode,
} from "tsparticles";

const NeedleParticles: React.FC = () => {
  const { state } = useContext(GameContext);

  const options: RecursivePartial<IOptions> = {
    fullScreen: {
      enable: true,
      zIndex: 100,
    },
    fpsLimit: 80,

    particles: {
      number: {
        value: 0,
      },
      color: {
        value: ["#4e93be", "#fff"],
        animation: {
          speed: 90,
          sync: true,
        },
      },
      shape: {
        type: ShapeType.circle,
      },
      opacity: {
        value: 1,
      },
      size: {
        value: { min: 1, max: 5 },
        animation: {
          enable: true,
          speed: 5,
          minimumValue: 1,
          sync: true,
          startValue: StartValueType.min,
          count: 1,
        },
      },
      move: {
        enable: true,
        speed: { min: 10, max: 20 },
        direction: MoveDirection.top,
        random: false,
        straight: false,
        outMode: OutMode.destroy,
        gravity: {
          enable: true,
        },
      },
    },
    interactivity: {
      detectsOn: InteractivityDetect.window,
      events: {
        // onclick: {
        //   enable: true,
        //   mode: ClickMode.trail,
        // },

        onHover: {
          enable: state.gameStatus === Status.Playing,
          mode: HoverMode.trail,
        },

        resize: true,
      },
      modes: {
        trail: {
          delay: 0.2,
          quantity: 1,
        },
      },
    },
    retina_detect: true,
  };

  return <Particles className="game__particles" options={options} />;
};

export default NeedleParticles;

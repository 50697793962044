export const PERCENTAGE = 19;
export const DELTA = 50;
export const PAINT_COLOR = "rgb(33, 33, 33)";
export const PAINT_WITH = 5;

export const CANVAS = {
  size: 600,
  externalCircle: {
    radius: 280,
    lineWidth: 25,
    strokeStyle: "rgba(46, 46, 46, 1)",
    fillStyle: "rgba(104, 104, 104, 1)",
  },

  internalCircle: {
    radius: 250,
    lineWidth: 15,
    strokeStyle: "rgba(0, 0, 0, 1)",
    fillStyle: "rgba(93, 144, 184, 1)",
  },
};

export const HEART_COLOR = "(60, 90, 110)";

export const IS_IOS =
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform) ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);

export const ANDROID_BLUE = "(100, 150, 190)";
export const IOS_BLUE = [
  "(70, 90, 110)",
  "(70, 90, 110)",
  "(70, 100, 130)",
  "(100, 150, 190)",
];

import logo from "../../assets/san-logo.png";
import Content from "../content";
import "./style.scss";

const Info = () => (
  <div className="info">
    <a
      className="info__logo"
      href="https://www.san-i.co.il/"
      target="_blank"
      rel="noreferrer"
    >
      <img src={logo} alt="san-interactive logo" />
    </a>

    <Content />
  </div>
);

export default Info;

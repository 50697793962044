import { Status } from "./state";

export enum ActionType {
  SetGameStatus,
  ResetGame,
}

export interface SetGameStatus {
  type: ActionType.SetGameStatus;
  payload: Status;
}

export interface ResetGame {
  type: ActionType.ResetGame;
}

export type GameActions = SetGameStatus | ResetGame;

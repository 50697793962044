import { useContext, useState } from "react";
import Canvas from "../../components/canvas";
import NeedleParticles from "../../components/needle-particles";
import { GameContext } from "../../store/context";
import { Status } from "../../store/state";
import { CSSTransition } from "react-transition-group";
import Heart from "../heart";
import "./style.scss";

function Game() {
  const { state } = useContext(GameContext);
  const [showHeart, setShowHeart] = useState(false);

  return (
    <div className="game">
      <NeedleParticles />

      <CSSTransition
        timeout={500}
        in={
          state.gameStatus === Status.NotPlaying ||
          state.gameStatus === Status.Playing
        }
        classNames="fade-cookie"
        onExited={() => setShowHeart(true)}
        onEnter={() => setShowHeart(false)}
        unmountOnExit
      >
        <Canvas />
      </CSSTransition>

      {showHeart && <Heart />}
    </div>
  );
}

export default Game;

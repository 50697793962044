import { createContext, useReducer, Dispatch, FC } from "react";
import { GameActions } from "./actions";
import { gameReducer } from "./reducer";
import { initialGameState, GameState } from "./state";

export type Context = {
  state: GameState;
  dispatch: Dispatch<GameActions>;
};

export const GameContext = createContext<Context>({
  state: initialGameState,
  dispatch: () => undefined,
});

export const GameProvider: FC = props => {
  const [state, dispatch] = useReducer(gameReducer, initialGameState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GameContext.Provider>
  );
};

import { useRef, MouseEvent, TouchEvent } from "react";
import { useCanvas } from "./utils/useCanvas";
import "./style.scss";

const Canvas = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [startDrwing, finishtDrwing, draw] = useCanvas(canvasRef);

  const handleMouseDown = (e: MouseEvent<HTMLCanvasElement>) =>
    startDrwing(e.clientX, e.clientY);

  const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) =>
    draw(e.clientX, e.clientY);

  const handleMouseUp = (e: MouseEvent<HTMLCanvasElement>) =>
    finishtDrwing(e.clientX, e.clientY);

  const handleTouchStart = (e: TouchEvent<HTMLCanvasElement>) =>
    startDrwing(e.touches[0].clientX, e.touches[0].clientY);

  const handleTouchMove = (e: TouchEvent<HTMLCanvasElement>) =>
    draw(e.touches[0].clientX, e.touches[0].clientY);

  const handleTouchUp = (e: TouchEvent<HTMLCanvasElement>) =>
    finishtDrwing(e.changedTouches[0].clientX, e.changedTouches[0].clientY);

  return (
    <>
      <canvas
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchUp}
        ref={canvasRef}
      />
    </>
  );
};

export default Canvas;

import Info from "../../components/info";
import Game from "../../components/game";
import Footer from "../../components/footer";
import { GameProvider } from "../../store/context";
import "./style.scss";

const GameScreen = () => (
  <div className="game-screen">
    <GameProvider>
      <Info />
      <Game />
    </GameProvider>
    <Footer />
  </div>
);

export default GameScreen;

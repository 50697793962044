import { useContext } from "react";
import { GameContext } from "../../store/context";
import { resetGame } from "../../store/reducer";
import RotatedWord from "../rotated-word";

const LoosingContent = () => {
  const { dispatch } = useContext(GameContext);

  return (
    <>
      <h1 className="content__loosing">
        <span>Hey...</span>
        <br></br>
        <span>You Are Shaking My Friend.</span>
        <br></br>
        <RotatedWord word="Keep It Positive " duration={3} delay={8} />
        <span> & Try Again,</span>
        <br></br>
        <span>The Saniz.</span>
      </h1>
      <button onClick={() => dispatch(resetGame())}>Try Again</button>
    </>
  );
};

export default LoosingContent;

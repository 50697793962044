import "./style.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <span className="copyright">
        All Rights Reserved © Copyright 2022 - Made with{" "}
        <span id="heart-copyright">♡</span> by{" "}
        <a
          className="copyright__link"
          href="https://www.san-i.co.il/"
          target="_blank"
          rel="noreferrer"
        >
          san interactive
        </a>
      </span>
    </footer>
  );
};

export default Footer;

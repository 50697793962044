import { useContext } from "react";
import { GameContext } from "../../store/context";
import { Status } from "../../store/state";
import WinningContent from "../winning-content";
import LoosingContent from "../loosing-content";
import IntroContent from "../intro-content";

import "./style.scss";

const Content = () => {
  const { state } = useContext(GameContext);

  const getContent = () => {
    let content = undefined;

    switch (state.gameStatus) {
      case Status.Winning:
        content = <WinningContent />;
        break;

      case Status.Loosing:
        content = <LoosingContent />;
        break;

      default:
        content = <IntroContent />;
    }

    return content;
  };

  return <div className="content">{getContent()}</div>;
};

export default Content;

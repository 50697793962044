import RotatedWord from "../rotated-word";

const IntroContent = () => {
  return (
    <>
      <h1 className="content__intro">
        <span>the</span>
        <RotatedWord word="dalgona" duration={4.5} delay={8} />
        <span>cookie game by saniz</span>
      </h1>
    </>
  );
};

export default IntroContent;

import { useContext } from "react";
import { GameContext } from "../../store/context";
import { Status } from "../../store/state";
import "./style.scss";

const Heart = () => {
  const { state } = useContext(GameContext);
  const winningClass =
    state.gameStatus === Status.Winning
      ? "heart-loader__heartPath--winning"
      : "";

  return (
    <svg
      className="heart-loader"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 90 90"
      version="1.1"
    >
      <g className="heart-loader__group">
        <path
          className="heart-loader__square"
          strokeWidth="1"
          fill="none"
          d="M0,30 0,90 60,90 60,30z"
        />

        <path
          className={`heart-loader__heartPath ${winningClass}`}
          strokeWidth="2"
          d="M60,30 a30,30 0 0,1 0,60 L0,90 0,30 a30,30 0 0,1 60,0"
        />
      </g>

      <g className="logo__colorGroup">
        {state.gameStatus === Status.Loosing && (
          <path
            className="heart-loader__stroke"
            fill="none"
            strokeWidth="2"
            d="M62,28 55,42 38,40 36,60 20,60 18,82 0,90 -1,91"
          />
        )}
      </g>
    </svg>
  );
};

export default Heart;

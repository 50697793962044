import { RefObject, useRef, useState, useContext, useEffect } from "react";
import { GameContext } from "../../../store/context";
import { Status } from "../../../store/state";
import { setGameStaus } from "../../../store/reducer";
import heart from "../../../assets/heart.svg";
import * as constants from "../../../constants";

type circleType = {
  radius: number;
  lineWidth: number;
  strokeStyle: string;
  fillStyle: string;
};

interface StringArray {
  [index: string]: number;
}

export const useCanvas = (canvasRef: RefObject<HTMLCanvasElement>) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startingPoint, setStartingPoint] = useState({ x: -1, y: -1 });
  const { dispatch } = useContext(GameContext);
  const numOfHeartPixels = useRef(0);
  const numOfPaintingHeartPixels = useRef(0);

  useEffect(() => {
    if (!canvasRef.current) return;

    canvasRef.current.width = constants.CANVAS.size;
    canvasRef.current.height = constants.CANVAS.size;

    const ctx = canvasRef.current.getContext("2d");
    const { externalCircle, internalCircle } = constants.CANVAS;

    if (!ctx) return;

    createCircle(ctx, externalCircle);
    createCircle(ctx, internalCircle);

    /** Load image */

    /**
     *
     * @returns Store colors,
     */
    const storeColors = () => {
      if (!canvasRef.current) return;
      const canvasWidth = canvasRef.current.width;
      const canvasHeight = canvasRef.current.height;
      const ctx = canvasRef.current.getContext("2d");
      const { HEART_COLOR } = constants;
      const idata = ctx!.getImageData(0, 0, canvasWidth, canvasHeight);
      const buffer32 = new Uint32Array(idata.data.buffer);
      const stats: StringArray = {};
      let i: number;

      ctx!.drawImage(
        img,
        canvasWidth / 2 - img.width / 2,
        canvasHeight / 2 - img.height / 2
      );

      for (i = 0; i < buffer32.length; i++) {
        storeColor(stats, buffer32[i]);
      }

      numOfHeartPixels.current = Math.round(stats[HEART_COLOR]);
    };

    /**
     * Store colors
     * @param stats
     * @param color
     */
    const storeColor = (stats: StringArray, color: number) => {
      const rgb = color & 0xffffff;
      const r = roundGratestMultiple(rgb & 0xff, 10);
      const g = roundGratestMultiple((rgb & 0xff00) >>> 8, 10);
      const b = roundGratestMultiple((rgb & 0xff0000) >>> 16, 10);

      const key = `(${r}, ${g}, ${b})`;

      if (!stats[key]) stats[key] = 0;

      stats[key]++;
    };

    const img = new Image();

    img.onload = () => {
      if (!canvasRef.current) return;
      storeColors();
    };

    img.src = heart;
  }, [canvasRef]);

  /**
   * Create circle on canvas.
   * @param ctx
   * @param circle
   */
  const createCircle = (ctx: CanvasRenderingContext2D, circle: circleType) => {
    const center = constants.CANVAS.size / 2;

    ctx.beginPath();
    ctx.arc(center, center, circle.radius, 0, 2 * Math.PI);
    ctx.lineWidth = circle.lineWidth;
    ctx.strokeStyle = circle.strokeStyle;
    ctx.stroke();
    ctx.fillStyle = circle.fillStyle;
    ctx.fill();
  };

  /**
   * Get mouse current position.
   * @param clientX
   * @param clientY
   * @returns
   */
  const getMousePos = (clientX: number, clientY: number) => {
    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect(); // abs. size of element.
      const scaleX = canvasRef.current.width / rect.width; // relationship bitmap vs. element for X.
      const scaleY = canvasRef.current.height / rect.height; // relationship bitmap vs. element for Y.
      // const scaleX = canvasRef.current.width / canvasRef.current.offsetWidth; // relationship bitmap vs. element for X.
      // const scaleY = canvasRef.current.height / canvasRef.current.offsetHeight; // relationship bitmap vs. element for Y.

      return [
        (clientX - rect.left) * scaleX, // scale mouse coordinates after they have.
        (clientY - rect.top) * scaleY, // been adjusted to be relative to element.
      ];
    } else {
      return [-1, -1];
    }
  };

  /**
   * Round number to the gratest multiple.
   * @param number
   * @param multiply
   * @returns
   */
  const roundGratestMultiple = (number: number, multipe: number) =>
    Math.ceil(number / multipe) * multipe;

  /**
   *
   * @param vibrations
   */
  const vibrateDevice = (vibrations: number[]) => {
    if ("vibrate" in navigator) {
      navigator.vibrate(vibrations);
    }
  };

  /**
   * Check if player has win.
   * @param clientX
   * @param clientY
   * @returns
   */
  const checkWinning = (clientX: number, clientY: number) => {
    const { DELTA, PERCENTAGE } = constants;

    const percentage =
      (numOfPaintingHeartPixels.current / numOfHeartPixels.current) * 100;

    if (
      Math.abs(startingPoint.x - clientX) < DELTA &&
      Math.abs(startingPoint.y - clientY) < DELTA &&
      percentage > PERCENTAGE
    ) {
      numOfPaintingHeartPixels.current = 0;
      vibrateDevice([300, 150, 300]);
      dispatch(setGameStaus(Status.Winning));
    }
  };

  /**
   * Check if player has loose.
   * @param pixelData
   */
  const checkLoosing = (pixelData: Uint8ClampedArray) => {
    const { ANDROID_BLUE, IOS_BLUE, IS_IOS, HEART_COLOR } = constants;
    const r = roundGratestMultiple(pixelData[0], 10);
    const g = roundGratestMultiple(pixelData[1], 10);
    const b = roundGratestMultiple(pixelData[2], 10);

    const rgb = `(${r}, ${g}, ${b})`;

    // alert(rgb);

    let isBlue = false;

    if (IS_IOS) {
      isBlue = IOS_BLUE.some(color => color === rgb);
    } else {
      isBlue = rgb === ANDROID_BLUE;
    }

    if (isBlue) {
      numOfPaintingHeartPixels.current = 0;
      vibrateDevice([500]);
      dispatch(setGameStaus(Status.Loosing));
    } else if (rgb === HEART_COLOR) {
      numOfPaintingHeartPixels.current += 1;
    }
  };

  /**
   * Start draw on canvas.
   * @param clientX
   * @param clientY
   * @returns
   */
  const startDrwing = (clientX: number, clientY: number) => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext("2d");

    if (!ctx) return;

    document.body.classList.add("prevent-scroll");

    dispatch(setGameStaus(Status.Playing));

    const [offsetX, offsetY] = getMousePos(clientX, clientY);

    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);

    if (startingPoint.x === -1 && startingPoint.y === -1) {
      setStartingPoint({ x: clientX, y: clientY });
    }
  };

  /**
   * Finish draw on canvas.
   * @param clientX
   * @param clientY
   * @returns
   */
  const finishtDrwing = (clientX: number, clientY: number) => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext("2d");

    if (!ctx) return;
    document.body.classList.remove("prevent-scroll");

    ctx.closePath();
    setIsDrawing(false);
    checkWinning(clientX, clientY);
  };

  /**
   * Draw on canvas.
   * @param clientX
   * @param clientY
   * @returns
   */
  const draw = (clientX: number, clientY: number) => {
    if (!canvasRef.current || !isDrawing) return;

    const ctx = canvasRef.current.getContext("2d");

    if (!ctx) return;

    const { PAINT_COLOR, PAINT_WITH } = constants;
    const [offsetX, offsetY] = getMousePos(clientX, clientY);

    ctx.strokeStyle = PAINT_COLOR;
    ctx.lineWidth = PAINT_WITH;
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();

    let pixelData = ctx.getImageData(offsetX, offsetY, 1, 1).data;

    checkLoosing(pixelData);
  };

  return [startDrwing, finishtDrwing, draw];
};

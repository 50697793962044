import { useContext } from "react";
import { GameContext } from "../../store/context";
import { resetGame } from "../../store/reducer";
import RotatedWord from "../rotated-word";

const WinningContent = () => {
  const { dispatch } = useContext(GameContext);

  return (
    <>
      <h1 className="content__winning">
        <span>Yehhh!</span>
        <br></br>
        <span>You Nailed It.</span>
        <RotatedWord word="Happy New Year" duration={3} delay={8} />

        <span>Keep Rocking :)</span>
        <br></br>
        <span>The Saniz.</span>
      </h1>
      <button onClick={() => dispatch(resetGame())}>Play Again</button>
    </>
  );
};

export default WinningContent;

import { GameState, Status } from "./state";
import { GameActions, ActionType, SetGameStatus, ResetGame } from "./actions";

export const gameReducer = (
  state: GameState,
  action: GameActions
): GameState => {
  switch (action.type) {
    case ActionType.SetGameStatus:
      return { ...state, gameStatus: action.payload };

    case ActionType.ResetGame:
      return { ...state, gameStatus: Status.NotPlaying };

    default:
      return state;
  }
};

// helper functions to simplify the caller
export const setGameStaus = (status: Status): SetGameStatus => ({
  type: ActionType.SetGameStatus,
  payload: status,
});

export const resetGame = (): ResetGame => ({ type: ActionType.ResetGame });

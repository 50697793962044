import { useEffect } from "react";
import GameScreen from "./screens/game-screen";
import ReactGA from "react-ga";
import "./global-style/index.scss";
import "./App.scss";

const App = () => {
  useEffect(() => {
    ReactGA.initialize("UA-8681400-7");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <GameScreen />
    </div>
  );
};

export default App;

import "./style.scss";

type RotatedWordType = {
  word: string;
  duration: number;
  delay: number;
};

const RotatedWord: React.FC<RotatedWordType> = ({ word, duration, delay }) => (
  <div className="rotated-word">
    {Array.from(word).map((letter, key) => {
      const style = {
        "--delay": `${key / delay}s`,
        "--duration": `${duration}s`,
      } as React.CSSProperties;

      return letter === " " ? (
        <span key={key}> </span>
      ) : (
        <span key={key} className="rotated-word__letter" style={style}>
          {letter === " " ? "_" : letter}
        </span>
      );
    })}
  </div>
);

export default RotatedWord;
